import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="rogue"></a>ROGUE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG50_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any.</p>
    <p><b>Hit Die</b>: d6.</p>
    <h6>Class Skills</h6>
    <p className="initial">The rogue&#8217;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#appraise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Appraise</a>
(Int), <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a> (Dex), <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher Script</a> (Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
(Cha), <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> (Int), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
(Cha), <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape Artist</a> (Dex), <a href="skillsAll.html#forgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Forgery</a>
(Int), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> (Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (local) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
(Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> (Dex), <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open
Lock</a> (Dex), <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a> (Cha), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> (Int), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> (Wis), <a href="skillsAll.html#sleight-of-hand" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sleight of Hand</a> (Dex), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str), <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a> (Dex), <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a> (Cha), and <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use
Rope</a> (Dex).</p>
    <p><b>Skill Points at 1st Level</b>: (8 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 8 + Int modifier.</p>
    <p><a id="table-the-rogue"></a><b>Table: The Rogue</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>Sneak attack +1d6, trapfinding</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+0</td>
          <td>Evasion</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>Sneak attack +2d6, trap sense +1</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Sneak attack +3d6</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Trap sense +2</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Sneak attack +4d6</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+6</td>
          <td>+2</td>
          <td>Improved uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+6</td>
          <td>+3</td>
          <td>Sneak attack +5d6, trap sense +3</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+3</td>
          <td>+7</td>
          <td>+3</td>
          <td>Special ability</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>+3</td>
          <td>Sneak attack +6d6</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>Trap sense +4</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+4</td>
          <td>Sneak attack +7d6, special ability</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+9</td>
          <td>+4</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+9</td>
          <td>+5</td>
          <td>Sneak attack +8d6, trap sense +5</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+5</td>
          <td>Special ability</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+5</td>
          <td>Sneak attack +9d6</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+11</td>
          <td>+6</td>
          <td>Trap sense +6</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+11</td>
          <td>+6</td>
          <td>Sneak attack +10d6, special ability</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">&#8212;</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="rogue-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
rogue.</p>
    <p><b>Weapon and Armor Proficiency</b>: Rogues are proficient
with all simple weapons, plus the hand crossbow, rapier, sap, shortbow,
and
short sword. Rogues are proficient with light armor, but not with
shields.</p>
    <p><a id="rogue-sneak-attack"></a><b>Sneak Attack</b>: If a rogue can catch an opponent when he
is unable to defend himself effectively from her attack, she can strike
a vital spot for extra damage. The rogue&#8217;s attack deals extra damage
any time her target would be denied a Dexterity bonus to AC (whether
the target actually has a Dexterity bonus or not), or when the rogue
flanks her target. This extra damage is 1d6 at 1st level, and it
increases by 1d6 every two rogue levels thereafter. Should the rogue
score a critical hit with a sneak attack, this extra damage is not
multiplied.</p>
    <p>Ranged attacks can count as sneak attacks only if the target
is within 30 feet.</p>
    <p>With a sap (blackjack) or an unarmed strike, a rogue can make
a sneak attack that deals nonlethal damage instead of lethal damage.
She cannot use a weapon that deals lethal damage to deal nonlethal
damage in a sneak attack, not even with the usual &#8211;4 penalty.</p>
    <p>A rogue can sneak attack only living creatures with
discernible anatomies&#8212;undead, constructs, oozes, plants, and <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures lack vital
areas to attack. Any creature that is
immune to critical hits is not vulnerable to sneak attacks. The rogue
must be able to see the target well enough to pick out a vital spot and
must be able to reach such a spot. A rogue cannot sneak attack while
striking a creature with concealment or striking the limbs of a
creature whose vitals are beyond reach.</p>
    <p><a id="rogue-trapfinding"></a><b>Trapfinding</b>: Rogues (and only rogues) can use the
Search skill to locate traps when the task has a Difficulty Class
higher than 20. Finding a nonmagical trap has a DC of at least 20, or
higher if it is well hidden. Finding a magic trap has a DC of 25 + the
level of the spell used to create it.</p>
    <p>Rogues (and only rogues) can use the Disable Device skill to
disarm magic traps. A magic trap generally has a DC of 25 + the level
of the spell used to create it.</p>
    <p>A rogue who beats a trap&#8217;s DC by 10 or more with a Disable
Device check can study a trap, figure out how it works, and bypass it
(with her party) without disarming it.</p>
    <p><a id="rogue-evasion"></a><b>Evasion (Ex)</b>: At 2nd level and higher, a rogue can
avoid even magical and unusual attacks with great agility. If she makes
a successful Reflex saving throw against an attack that normally deals
half damage on a successful save, she instead takes no damage. Evasion
can be used only if the rogue is wearing light armor or no armor. A <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> rogue does not gain the
benefit of evasion.</p>
    <p><a id="rogue-trap-sense"></a><b>Trap Sense (Ex)</b>: At 3rd level, a rogue gains an
intuitive sense that alerts her to danger from traps, giving her a +1
bonus on Reflex saves made to avoid traps and a +1 dodge bonus to AC
against attacks made by traps. These bonuses rise to +2 when the rogue
reaches 6th level, to +3 when she reaches 9th level, to +4 when she
reaches 12th level, to +5 at 15th, and to +6 at 18th level.</p>
    <p>Trap sense bonuses gained from multiple classes stack.</p>
    <p><a id="rogue-uncanny-dodge"></a><b>Uncanny Dodge (Ex)</b>: Starting at 4th level, a rogue can
react to danger before her senses would normally allow her to do so.
She retains her Dexterity bonus to AC (if any) even if she is caught <a href="abilitiesAndConditions.html#flat-footed" style={{
        "color": "rgb(87, 158, 182)"
      }}>flat-footed</a> or struck by an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> attacker. However, she
still
loses her Dexterity bonus to AC if immobilized.</p>
    <p>If a rogue already has uncanny dodge from a different class
she automatically gains improved uncanny dodge (see below) instead.</p>
    <p><a id="rogue-improved-uncanny-dodge"></a><b>Improved Uncanny Dodge (Ex)</b>: A rogue of 8th level or
higher can no longer be flanked. This defense denies another rogue the
ability to sneak attack the character by flanking her, unless the
attacker has at least four more rogue levels than the target does.</p>
    <p>If a character already has uncanny dodge (see above) from a
second class, the character automatically gains improved uncanny dodge
instead, and the levels from the classes that grant uncanny dodge stack
to determine the minimum rogue level required to flank the character.</p>
    <p><a id="rogue-special-abilities"></a><b>Special Abilities</b>: On attaining 10th level, and at
every three levels thereafter (13th, 16th, and 19th), a rogue gains a
special ability of her choice from among the following options.</p>
    <p><a id="rogue-crippling-strike"></a><i>Crippling Strike (Ex)</i>: A rogue with this ability can
sneak attack opponents with such precision that her blows weaken and
hamper them. An opponent damaged by one of her sneak attacks also takes
2 points of Strength damage. Ability points lost to damage return on
their own at the rate of 1 point per day for each damaged ability.</p>
    <p><a id="rogue-defensive-roll"></a><i>Defensive Roll (Ex)</i>: The rogue can roll with a
potentially lethal blow to take less damage from it than she otherwise
would. Once per day, when she would be reduced to 0 or fewer hit points
by damage in combat (from a weapon or other blow, not a spell or
special ability), the rogue can attempt to roll with the damage. To use
this ability, the rogue must attempt a Reflex saving throw (DC = damage
dealt). If the save succeeds, she takes only half damage from the blow;
if it fails, she takes full damage. She must be aware of the attack and
able to react to it in order to execute her defensive roll&#8212;if she is
denied her Dexterity bonus to AC, she can&#8217;t use this ability. Since
this effect would not normally allow a character to make a Reflex save
for half damage, the rogue&#8217;s evasion ability does not apply to the
defensive roll.</p>
    <p><a id="rogue-improved-evasion"></a><i>Improved Evasion (Ex)</i>: This ability works like evasion,
except that while the rogue still takes no damage on a successful
Reflex saving throw against attacks henceforth she henceforth takes
only half damage on a failed save. A <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> rogue does not gain the
benefit of improved evasion.</p>
    <p><a id="rogue-opportunist"></a><i>Opportunist (Ex)</i>: Once per round, the rogue can make an
attack of opportunity against an opponent who has just been struck for
damage in melee by another character. This attack counts as the rogue&#8217;s
attack of opportunity for that round. Even a rogue with the <a href="featsAll.html#combat-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat
Reflexes</a> feat can&#8217;t use the opportunist ability more than once per
round.</p>
    <p><a id="rogue-skill-mastery"></a><i>Skill Mastery</i>: The rogue becomes so certain in the use
of certain skills that she can use them reliably even under adverse
conditions. Upon gaining this ability, she selects a number of skills
equal to 3 + her Intelligence modifier. When making a skill check with
one of these skills, she may take 10 even if stress and distractions
would normally prevent her from doing so. A rogue may gain this special
ability multiple times, selecting additional skills for it to apply to
each time.</p>
    <p><a id="rogue-slippery-mind"></a><i>Slippery Mind (Ex)</i>: This ability represents the rogue&#8217;s
ability to wriggle free from magical effects that would otherwise
control or compel her. If a rogue with slippery mind is affected by an
enchantment spell or effect and fails her saving throw, she can attempt
it again 1 round later at the same DC. She gets only this one extra
chance to succeed on her saving throw.</p>
    <p><i>Feat</i>: A rogue may gain a bonus feat in place of a
special ability.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      